<template>
  <v-autocomplete
    v-model="choiceInSelectedLanguage"
    :items="optionsInSelectedLanguage"
    return-object
    v-bind="$attrs"
    item-value="id"
    item-text="translation"
  >
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    // the value property is an array of translations. Each translations
    // must consist of (at least) an id and translation property.
    // value = [ { id = 'xxxx-xxxx-xxxx-xxxx', translation = 'my translation' } ]
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    // each item in the items array is another array in the same form as the value property
    items: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  mounted() {
    this.updateChoice();
  },

  watch: {
    value: {
      handler() {
        this.updateChoice();
      },
      deep: true
    },

    choiceInSelectedLanguage() {
      // when a option was selected we need to take care of the mapping.
      // If the return-object prop is not set we can just return the id and we're done.
      // However with the return-object prop set we want to return the initial structure
      // as passed as a value which was an array with multiple translations. To do so we'll
      // get the array from the items-property and emit it.

      const returnObj = this.$attrs['return-object'] !== undefined;

      if (returnObj) {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i][0].id === this.choiceInSelectedLanguage.id) {
            this.$emit('input', this.items[i]);
            return;
          }
        }
      } else {
        this.$emit('input', this.choiceInSelectedLanguage.id);
      }
    }
  },

  computed: {
    optionsInSelectedLanguage() {
      // map the items array to another array which only
      // includes the items in the current language. This step is neccessary
      // because the v-autocomplete cannot handle an array with multiple
      // translations as a v-model very well.
      return this.items.map((x) => {
        const langItems = x.filter(
          (item) => item.languageKey === this.currentLanguageKey
        );
        if (langItems.length > 0) {
          return langItems[0];
        } else {
          return x[0];
        }
      });
    },

    currentLanguageKey() {
      return this.languageKey;
    }
  },

  methods: {
    updateChoice() {
      const choiceTranslation = this.value.filter(
        (x) => x.languageKey === this.currentLanguageKey
      );

      if (choiceTranslation.length > 0) {
        this.choiceInSelectedLanguage = choiceTranslation[0];
      } else {
        this.choiceInSelectedLanguage = this.value[0];
      }
    }
  },

  data() {
    return {
      choiceInSelectedLanguage: ''
    };
  }
};
</script>
