<template>
  <v-card class="segmented flex d-flex flex-column" flat>
    <v-card-title>
      <v-row class="align-center">
        <v-col cols="12">
          <h5>{{ $t('employeeDetails.basicData') }}</h5>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="employee !== null" class="flex">
      <br class="d-none d-md-block" />
      <v-row class="align-center">
        <v-col cols="12" sm="6" md="3" class="col-label">
          {{ $t('employeeDetails.shorthand') }}
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <v-text-field
            v-if="!readOnly"
            v-model="employee.shorthand"
            :placeholder="$t('employeeDetails.shortHand')"
            filled
            disabled
            hide-details
          ></v-text-field>

          <span v-else>{{ employee.shorthand }}</span>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col cols="12" sm="6" md="3" class="col-label">
          {{ $t('employeeDetails.firstName') }}
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <v-text-field
            v-if="!readOnly"
            v-model="employee.firstName"
            :placeholder="$t('employeeDetails.firstName')"
            filled
            hide-details
          ></v-text-field>

          <span v-else>{{ employee.firstName }}</span>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col cols="12" sm="6" md="3" class="col-label">
          {{ $t('employeeDetails.lastName') }}
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <v-text-field
            v-if="!readOnly"
            v-model="employee.lastName"
            :placeholder="$t('employeeDetails.lastName')"
            filled
            hide-details
          ></v-text-field>

          <template v-else>{{ employee.lastName }}</template>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col cols="12" sm="6" md="3" class="col-label">
          {{ $t('employeeDetails.supervisor') }}
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <v-autocomplete
            v-if="!readOnly"
            v-model="employee.supervisorId"
            :items="supervisorOptionsWithoutMentor"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
            filled
            hide-details
          ></v-autocomplete>

          <template v-else>{{ employee.supervisorName }}</template>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col cols="12" sm="6" md="3" class="col-label">
          {{ $t('employeeDetails.mentor') }}
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <v-autocomplete
            v-if="!readOnly"
            v-model="employee.mentorId"
            :items="mentorOptions"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
            clearable
            filled
            hide-details
          ></v-autocomplete>

          <template v-else>{{ employee.mentorName }}</template>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col cols="12" sm="6" md="3" class="col-label">
          {{ $t('employeeDetails.position') }}
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <locale-autocomplete
            v-if="!readOnly"
            v-model="employee.position"
            :items="positionOptions"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
            filled
            return-object
            hide-details
            item-text="translation"
          >
          </locale-autocomplete>

          <template v-else>{{ localize(employee.position) }}</template>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col cols="12" sm="6" md="3" class="col-label">
          {{ $t('employeeDetails.hierarchy') }}
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <locale-autocomplete
            v-if="!readOnly"
            v-model="employee.hierarchy"
            :items="hierarchyOptions"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
            filled
            return-object
            hide-details
          >
          </locale-autocomplete>

          <template v-else>{{ localize(employee.hierarchy) }}</template>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col cols="12" sm="6" md="3" class="col-label">
          {{ $t('employeeDetails.location') }}
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <locale-autocomplete
            v-if="!readOnly"
            v-model="employee.location"
            :items="locationOptions"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
            filled
            return-object
            hide-details
          >
          </locale-autocomplete>

          <template v-else>{{ localize(employee.location) }}</template>
        </v-col>
      </v-row>

      <v-row v-if="!readOnly" class="align-center">
        <v-col cols="12" sm="6" md="3" class="col-label">
          {{ $t('employeeDetails.lockApiUpdate') }}
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <v-switch
            v-model="employee.isApiUpdateLocked"
            :prepend-icon="apiLockIcon"
            color="secondary"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row v-if="!readOnly" class="align-center">
        <v-col cols="12" md="5" offset-md="7">
          <v-btn color="primary" :loading="saving" @click="save" block>
            <v-icon class="mr-4">mdi-floppy</v-icon>
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions v-if="!readOnly">
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn text small @click="showHistoryModal">
            <v-icon small class="mr-2">mdi-history</v-icon>
            {{ $t('changeHistory.title') }}
          </v-btn>
        </v-col>
      </v-row>

      <change-history
        v-model="historyDialog"
        :historyData="historyData"
        @close="historyDialog = false"
      ></change-history>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

import changeHistory from '@/components/employeeDetails/change-history-modal';
import localeAutocomplete from '@/components/misc/localization-autocomplete.vue';

export default {
  components: {
    changeHistory,
    localeAutocomplete
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    positionOptions: {
      type: Array,
      required: true
    },

    supervisorOptions: {
      type: Array,
      required: false,
      default: () => []
    },

    hierarchyOptions: {
      type: Array,
      required: true
    },

    locationOptions: {
      type: Array,
      required: true
    },

    saving: {
      type: Boolean,
      required: false,
      default: false
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    employee: null,
    historyDialog: false,
    historyDialogLoading: true,
    historyData: null
  }),

  computed: {
    apiLockIcon() {
      if (this.employee.apiLock) {
        return 'mdi-lock';
      } else {
        return 'mdi-lock-open';
      }
    },

    supervisorId() {
      if (this.employee === null) {
        return null;
      }

      return this.employee.supervisorId;
    },

    mentorId() {
      if (this.employee === null) {
        return null;
      }

      return this.employee.mentorId;
    },

    supervisorName() {
      if (this.employee === null) {
        return '';
      }

      const supervisor = this.supervisorOptions.filter(
        (x) => x.value === this.employee.supervisorId
      );

      if (supervisor.length > 0) {
        return supervisor[0].text;
      } else {
        return '';
      }
    },

    mentorName() {
      if (this.employee === null) {
        return '';
      }

      const mentor = this.mentorOptions.filter(
        (x) => x.value === this.employee.mentorId
      );

      if (mentor.length > 0) {
        return mentor[0].text;
      } else {
        return '';
      }
    },

    mentorOptions() {
      return this.supervisorOptions.filter(
        (x) => x.value !== this.employee.supervisorId
      );
    },

    supervisorOptionsWithoutMentor() {
      return this.supervisorOptions.filter(
        (x) => x.value !== this.employee.mentorId
      );
    }
  },

  watch: {
    supervisorId() {
      const supervisor = this.supervisorOptions.filter(
        (x) => x.value === this.supervisorId
      );

      if (supervisor.length === 0) {
        return;
      }

      this.employee.supervisorName = supervisor[0].text;
    },

    mentorId() {
      const mentor = this.mentorOptions.filter(
        (x) => x.value === this.mentorId
      );

      if (mentor.length === 0) {
        return;
      }

      this.employee.mentorName = mentor[0].text;
    },

    value() {
      this.employee = this.value;
    }
  },

  mounted() {
    this.employee = JSON.parse(JSON.stringify(this.value));
  },

  methods: {
    ...mapActions({
      fetchUserHistory: 'users/fetchUserHistory'
    }),

    save() {
      this.$emit('input', this.employee);
      this.$emit('save');
    },

    async showHistoryModal() {
      this.historyData = null;
      this.historyDialog = true;

      const data = await this.fetchUserHistory(this.employee.id);
      this.historyData = data;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'xs-only')} {
  .col-label {
    font-weight: bold;
  }

  .row > div {
    padding-top: 0;
    padding-bottom: 0;
  }

  .row {
    margin-bottom: 12px;
  }
}
</style>
