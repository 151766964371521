<template>
  <v-dialog v-model="value" hide-overlay persistent width="60%">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $t('changeHistory.title') }}</span>
      </v-card-title>
      <v-card-text>
        <div v-if="historyData === null">
          {{ $t('dataLoading') }}
          <v-progress-linear
            indeterminate
            color="primary"
            class="mt-4 mb-0"
            height="4"
          ></v-progress-linear>
        </div>
        <div v-else>
          <change-history :data="historyData"></change-history>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('close')">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import changeHistory from './changeHistory';

export default {
  components: {
    changeHistory
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },

    historyData: {
      type: Object,
      required: false,
      default: () => null
    }
  }
};
</script>
