<template>
  <v-dialog v-model="dialog" max-width="1024px">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-btn small text v-on="{ ...dialog }" v-bind="attrs">
        <slot name="button">
          <v-icon>mdi-menu</v-icon>
        </slot>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5"><slot name="header"></slot></span>
      </v-card-title>
      <v-card-text>
        <process-details :process="process"></process-details>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          <slot name="close-button">{{ $t('close') }}</slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import processDetails from '@/components/feedbacks/process-details.vue';

export default {
  props: {
    process: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    dialog: false
  }),

  methods: {
    close() {
      this.dialog = false;
    }
  },

  components: {
    processDetails
  }
};
</script>
