<template>
  <user-details :detailsRoute="'UserDetails'"></user-details>
</template>

<script>
import userDetails from '@/components/employeeDetails/user-details.vue';

export default {
  components: {
    userDetails
  }
};
</script>
