var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"default"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('feedbacks.add'))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t('feedbacks.add'))+" ")]),_c('v-card-text',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mt-4",attrs:{"type":"article, list-item, actions","loading":true}}):_c('div',{staticClass:"pt-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.feedbackTypes,"item-text":"label","item-value":"value","label":_vm.$t('feedbacks.type'),"required":"","rules":[function (v) { return v !== -1 || _vm.$t('validation.fieldRequired'); }]},model:{value:(_vm.feedbackData.processType),callback:function ($$v) {_vm.$set(_vm.feedbackData, "processType", $$v)},expression:"feedbackData.processType"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.feedbackForms,"label":_vm.$t('feedbacks.form'),"disabled":_vm.feedbackData.processType === -1,"menu-props":{
                  closeOnClick: true,
                  closeOnContentClick: true
                },"rules":[function (v) { return !!v || _vm.$t('validation.fieldRequired'); }],"required":""},model:{value:(_vm.feedbackData.formId),callback:function ($$v) {_vm.$set(_vm.feedbackData, "formId", $$v)},expression:"feedbackData.formId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.usersList,"label":_vm.$t('feedbacks.donor'),"menu-props":{
                  closeOnClick: true,
                  closeOnContentClick: true
                },"rules":[function (v) { return !!v || _vm.$t('validation.fieldRequired'); }],"return-object":"","required":""},model:{value:(_vm.feedbackData.donor),callback:function ($$v) {_vm.$set(_vm.feedbackData, "donor", $$v)},expression:"feedbackData.donor"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.usersList,"label":_vm.$t('feedbacks.mentor'),"menu-props":{
                  closeOnClick: true,
                  closeOnContentClick: true
                },"return-object":"","clearable":""},model:{value:(_vm.feedbackData.mentor),callback:function ($$v) {_vm.$set(_vm.feedbackData, "mentor", $$v)},expression:"feedbackData.mentor"}})],1)],1)],1)],1)],1),(!_vm.loading)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":_vm.createFeedback}},[_vm._v(" "+_vm._s(_vm.$t('feedbacks.add'))+" ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }