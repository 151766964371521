<template>
  <div>
    <v-row>
      <v-col cols="3">
        <span class="text-uppercase font-weight-bold">{{ $t('date') }}</span>
      </v-col>
      <v-col cols="3">
        <span class="text-uppercase font-weight-bold">
          {{ $t('changeHistory.field') }}
        </span>
      </v-col>
      <v-col cols="3">
        <span class="text-uppercase font-weight-bold">
          {{ $t('changeHistory.previousValue') }}
        </span>
      </v-col>
      <v-col cols="3">
        <span class="text-uppercase font-weight-bold">
          {{ $t('changeHistory.newValue') }}
        </span>
      </v-col>
    </v-row>

    <template v-for="(change, changeIndex) in data.changes">
      <v-row
        v-for="(item, itemIndex) in change.items"
        :key="`history_item_${changeIndex}_${itemIndex}`"
      >
        <v-col cols="3">
          <span v-show="itemIndex === 0">
            {{ change.date }}
          </span>
        </v-col>
        <v-col cols="3">{{ $t(`employeeDetails.${item.field}`) }}</v-col>
        <v-col cols="3">{{ localize(JSON.parse(item.previousValue)) }}</v-col>
        <v-col cols="3">{{ localize(JSON.parse(item.newValue)) }}</v-col>
      </v-row>
      <v-divider
        :key="`history_divider_${changeIndex}`"
        class="my-2"
      ></v-divider>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>
